// NPM Requirements
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import Hero from './Hero';
import LogoList from './LogoList';
import Meta from '../shared/Meta';
import Triple from './Triple';
import VendorsToChooseFrom from './VendorsToChooseFrom';
import CtaImage from './CtaImage';
import AnyEvent from './AnyEvent';

// Actions
import { clearServerRenderedPath } from '../../actions/uiActions';

// Selectors
import { getCityConfig } from '../../selectors';

// Misc
import IsoMorphUtils from '../../modules/isoMorphUtils';
import { getUrl } from '../../modules/helpers';
import styles from '../../../stylesheets/public/layouts/AboutPage.module.postcss';
import tripleStyles from './Triple.module.postcss';

class CommunityEvents extends Component {
  componentDidMount() {
    if (IsoMorphUtils.serverRendered(this.props)) {
      this.props.clearServerRenderedPath();
    }
  }

  render() {
    const { location, cityConfig } = this.props;

    const vehicleType = cityConfig.vehicle_type.toLowerCase();

    return (
      <div className="CateringPage">
        <Meta
          title={`Food ${cityConfig.vehicle_type}s For Events & Festivals | ${cityConfig.site_title}`}
          type="website"
          url={getUrl()}
          description={`Whether it's a store opening, neighborhood block party, or school fundraiser, food ${vehicleType}s are a good addition to any event.`}
          structuredData={{
            breadcrumbs: { listItems: [
              {
                "@type": "ListItem",
                "position": 2,
                "name": "Events & Festivals",
                "item": cityConfig.base_url + location.pathname,
              }
            ]}
          }}
        />

        <Hero photo="https://static.seattlefoodtruck.com/photos/Community_Events__HEADER.jpeg">
          <h1>Events &amp; Festivals</h1>
          <p>Whether it's a store opening, neighborhood block party, or school fundraiser, food {vehicleType}s are a good addition to any event. Use our request form below to connect with {cityConfig.city_name}'s best food {vehicleType}s!</p>
          <a href="/catering/register" className="Button">
            Get started
          </a>
        </Hero>

        <section className="PaddedSection">
          <div className="Container">
            <h3>How it works</h3>
            <div className={tripleStyles.Triple_wrapper}>
              <Triple
                image="https://static.seattlefoodtruck.com/photos/catering1.png"
                title="1. Tell Us About Your Event"
                text={`Answer a few questions about your upcoming event and what kind of food ${vehicleType}s you're looking for with our free online form.`}
              />
              <Triple
                image="https://static.seattlefoodtruck.com/photos/catering2.png"
                title={`2. Compare Available ${cityConfig.vehicle_type}s`}
                text={`Receive availability and quotes from ${cityConfig.city_name}'s best food ${vehicleType}s. Chat with vendors directly and discuss logistics and details.`}
              />
              <Triple
                image="https://static.seattlefoodtruck.com/photos/catering3.png"
                title={`3. Book Your ${cityConfig.vehicle_type}s`}
                text={`Once you've settled on your favorite food ${vehicleType}, book them directly without any added commission or service fees.`}
              />
            </div>
          </div>
        </section>

        <section className="PaddedSection--morePadding">
          <div className="Container">
            <div className={styles.Cta}>
              <CtaImage image="https://static.seattlefoodtruck.com/photos/Events1.jpeg" imgClass="img-events1"/>
              <div className={styles.Cta_copy}>
                <h4>Bring the fun to any event</h4>

                <p>Festivals, markets, and block parties need a simple way to feed their guests, without too many moving parts. That's why they use {cityConfig.city_name}'s best food {vehicleType}s to help! Food {vehicleType}s are the perfect way to feed a hungry crowd of any size, from 50 to 50,000! Whether you're looking to book one or ten {vehicleType}s, {cityConfig.site_title} gives you access to over 500 food {vehicleType}s to choose from! Choose from cuisines all over the world, giving your guests something to enjoy, while supporting small businesses along the way.</p>

                <div>
                <a href="/catering/register" className="Button">
                  Free Quote
                </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="PaddedSection--morePadding">
          <div className="Container">
            <div className={styles.Cta + " " + styles.Cta__reversed}>
              <CtaImage image="https://static.seattlefoodtruck.com/photos/Events2.jpeg" imgClass="img-events2"/>
              <div className={styles.Cta_copy}>
                <h4>Gourmet Food On Wheels</h4>

                <p>Give your music festival an upgrade from fair food with gourmet kitchens on wheels. Treat your neighborhood block party to an ice cream sandwich social with Street Treats food {vehicleType}! Compliment your store opening with fresh made-to-order beignets from {cityConfig.city_name} favorite, Where Ya At Matt food {vehicleType}! Delight parents and faculty at your school fundraiser with authentic Oaxacan tacos from the El Cabrito food {vehicleType}. Whatever your event, the possibilities are endless and these {cityConfig.city_name} food {vehicleType}s are only a click away! Use our free catering form below to start your request.</p>

                <div>
                  <a href="/catering/register" className="Button">
                    Book a Food {cityConfig.vehicle_type}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <VendorsToChooseFrom />

        <section className="PaddedSection">
          <div className="Container">
            <LogoList community />
          </div>
        </section>

        <AnyEvent/>

        <section className="PaddedSection">
          <div className="Container">
            <div className={styles.BookingCta}>
              <h3>Think of us for your next event</h3>
              <p>Free no-obligation estimates from {cityConfig.city_name}'s best food {vehicleType}s.</p>
              <a
                className="Button"
                href="/catering/register"
              >
                Food {cityConfig.vehicle_type} Catering
              </a>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

CommunityEvents.propTypes = {
  ui: PropTypes.object,
  clearServerRenderedPath: PropTypes.func,
  cityConfig: PropTypes.object,
};

function mapStateToProps(state, props) {
  return {
    ui: state.ui,
    cityConfig: getCityConfig(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearServerRenderedPath() {
      dispatch(clearServerRenderedPath());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CommunityEvents);
