import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Slider from 'react-slick';
import { connect } from 'react-redux';

// Actions
import truckActions from '../../actions/truckActions';

// Selectors
import { getAllTrucks } from '../../selectors';

import TruckTease from './TruckTease';
import { defaultSettings } from './ImageViewer';
import 'slick-carousel/slick/slick.css';
import styles from './ImageViewer.module.postcss';

class VendorsToChooseFrom extends Component {
  componentDidMount() {
    const { options } = this.props;
    this.props.fetchTrucks(_.merge({ page_size: 10 }, options));
  }

  render() {
    const { trucks } = this.props;

    if (trucks === null) {
      return null;
    }

    const settings = {
      ...defaultSettings,
      arrows: (trucks || []).length > 2,
      slidesToShow: 2,
      slidesToScroll: 2,
    };

    const slides = (trucks || []).map((truck, index) => {
      return (
        <div className={styles.ImageViewer_slide} key={index}>
          <TruckTease truck={truck} />
        </div>
      );
    });

    return (
      <section className={styles.VendorsToChooseFrom + " PaddedSection"}>
        <div className="Container">
          <h3>500+ Vendors to Choose From</h3>
          <div className={styles.ImageViewer}>
            <Slider {...settings}>
              {slides}
            </Slider>
          </div>
        </div>
      </section>
    );
  }
}

VendorsToChooseFrom.propTypes = {
  fetchTrucks: PropTypes.func,
  trucks: PropTypes.array,
};

function mapStateToProps(state, props) {
  return {
    trucks: getAllTrucks(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchTrucks(options) {
      dispatch(truckActions.fetch(options));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VendorsToChooseFrom);
